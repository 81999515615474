import React from 'react';
import { Route, BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { AvailableReservations } from './pages';

const NotFound = () => <Redirect to={{ pathname: '/' }} />;

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path='/' exact component={AvailableReservations} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;

import React from 'react';
import {
  makeStyles,
  createStyles,
  BottomNavigationAction,
  BottomNavigation,
} from '@material-ui/core';
import {
  AccessTime as AccessTimeIcon,
  Update as UpdateIcon,
} from '@material-ui/icons';
import { TimeRange } from '../types';

const useStyles = makeStyles((theme) =>
  createStyles({
    bottomNav: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

export interface BottomNavigationProps {
  timeRangeOption: number;
  onChangeTimeRangeOption: (option: number) => void;
}

export const BottomNav: React.FC<BottomNavigationProps> = ({
  timeRangeOption,
  onChangeTimeRangeOption,
}) => {
  const classes = useStyles();

  return (
    <BottomNavigation
      value={timeRangeOption}
      onChange={(event, newValue) => onChangeTimeRangeOption(newValue)}
      showLabels
      className={classes.bottomNav}
    >
      <BottomNavigationAction
        label={TimeRange.current}
        icon={<AccessTimeIcon />}
      />
      <BottomNavigationAction
        label={TimeRange.upcoming}
        icon={<UpdateIcon />}
      />
    </BottomNavigation>
  );
};

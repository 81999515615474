import { AuthenticationContext, AdalConfig } from 'react-adal';

export const adalConfig: AdalConfig = {
  tenant: process.env.REACT_APP_AD_TENTANT,
  clientId: process.env.REACT_APP_AD_CLIENT_ID || '',
  endpoints: {
    api: process.env.REACT_APP_AD_API || '',
  },
  cacheLocation: 'sessionStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

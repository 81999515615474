import React, { ComponentType, useEffect, useState } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import _ from 'lodash';
import moment from 'moment';
import { makeStyles, createStyles } from '@material-ui/core';
import { OfficeDropdownProps } from '../components/DayOptions/OfficeDropdown';
import { BottomNavigationProps } from '../components/BottomNavigation';
import { TopBarProps } from '../components/TopBar';

import {
  useWindowDimensions,
  useUserOffices,
  useCreateReservation,
  useUserPoints,
  useSendEmailWithReservations,
  useDeleteReservation,
} from '../hooks';
import { theme } from '../theme';
import {
  ConfirmDialogProps,
  ConfirmDialogContent,
} from '../components/ConfirmDialog';
import { onLogOut, getAuthUser } from '../utils/getAuthUser';

export interface AvailableReservationsProps
  extends TopBarProps,
    OfficeDropdownProps,
    ConfirmDialogProps,
    BottomNavigationProps {
  loading: boolean;
  onDayButtonClick: (
    date: string,
    reservedOffice?: { id: string; address: string }
  ) => void;
  onSendEmailButtonClick: () => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    legend: {
      fontSize: '1rem',
    },
    dateContainer: {
      fontSize: '1.2rem',
      textTransform: 'capitalize',
      color: theme.palette.secondary.dark,
      marginTop: 10,
      display: 'block',
    },
  })
);

export const withAvailableReservations = (
  WrappedComponent: ComponentType<AvailableReservationsProps>
) => () => {
  const authUser = getAuthUser();
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowDimensions();
  const { offices } = useUserOffices();
  const { userPoints } = useUserPoints();
  const [selectedOffice, setOffice] = useState<string>('');
  const { handleCreateReservation, loading } = useCreateReservation(
    selectedOffice
  );
  const {
    handleDeleteReservation,
    deleteReservationLoading,
  } = useDeleteReservation(selectedOffice);
  const {
    handleSendEmailWithReservations,
    sendEmailLoading,
  } = useSendEmailWithReservations();
  const [timeRangeOption, setTimeRangeOption] = useState<number>(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState<ConfirmDialogContent>({});

  const handleOpenSnackbar = (variant: VariantType, message: string) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  const classes = useStyles();

  useEffect(() => {
    if (!selectedOffice) {
      setOffice(
        localStorage.getItem('currentOffice') || _.get(offices, '[0].id', '')
      );
    }
  }, [selectedOffice, offices]);

  if (!authUser) {
    return null;
  }

  const userFullName: string = _.get(authUser, 'profile.name');
  const userName: string = _.get(authUser, 'profile.given_name');
  const userEmail: string = _.get(authUser, 'userName');
  const selectedOfficeData = _.find(offices, ['id', selectedOffice]);
  const selectedOfficeName = selectedOfficeData?.address ?? '';
  const mobileBreakpoint = theme.breakpoints.values.md;
  const mobileView = width <= mobileBreakpoint;

  const handleDayButtonClick = (
    date: string,
    reservedOffice?: { id: string; address: string }
  ) => {
    setDialogContent({
      dialogContent: (
        <>
          <span className={classes.legend}>
            {!reservedOffice ? (
              <>
                Une place en flex office à <b>{selectedOfficeName}</b> va être
                réservée le
              </>
            ) : (
              <>
                Votre réservation en flex office à{' '}
                <b>{reservedOffice.address}</b> va être annulée le
              </>
            )}
          </span>
          <br />
          <span className={classes.dateContainer}>
            {mobileView ? (
              <>
                {moment(date).format('dddd, ')}
                <br />
                {moment(date).format('DD MMMM Y')}
              </>
            ) : (
              moment(date).format('dddd, DD MMMM Y')
            )}
          </span>
        </>
      ),
      buttonText: !reservedOffice
        ? 'Réservez votre place'
        : 'Annulez votre place',
      buttonAction: () =>
        !reservedOffice
          ? handleConfirmReservation(date)
          : handleCancelReservation(date, reservedOffice.id),
      dialogTitle: 'Êtes-vous sûr?',
    });
    handleOpenConfirmDialog();
  };

  const handleLogoutButtonClick = () => {
    setDialogContent({
      dialogContent: <>Retour à l'écran de connexion</>,
      buttonText: 'Se déconnecter',
      buttonAction: onUserLogOut,
      dialogTitle: 'Êtes-vous sûr?',
    });
    handleOpenConfirmDialog();
  };

  const handleSendEmailButtonClick = () => {
    setDialogContent({
      dialogContent: (
        <>Souhaitez-vous recevoir par email un résumé de vos réservations?</>
      ),
      buttonText: 'Envoyer',
      buttonAction: handleConfirmEmailSending,
    });
    handleOpenConfirmDialog();
  };

  const handleOpenConfirmDialog = () => setOpenConfirmDialog(true);

  const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

  const handleChangeTimeRangeOption = (option: number) =>
    setTimeRangeOption(option);

  const handleChangeOffice = (id: string) => {
    localStorage.setItem('currentOffice', id);
    setOffice(id);
  };

  const handleConfirmReservation = (date: string) =>
    handleCreateReservation({
      date,
      userEmail,
      onCloseConfirmDialog: handleCloseConfirmDialog,
      onOpenSnackbar: handleOpenSnackbar,
    });

  const handleCancelReservation = (
    date: string,
    officeIdWithReservation: string
  ) =>
    handleDeleteReservation({
      date,
      officeIdWithReservation,
      onCloseConfirmDialog: handleCloseConfirmDialog,
      onOpenSnackbar: handleOpenSnackbar,
    });

  const handleConfirmEmailSending = () =>
    handleSendEmailWithReservations({
      onCloseConfirmDialog: handleCloseConfirmDialog,
      onOpenSnackbar: handleOpenSnackbar,
    });

  const onUserLogOut = () => {
    localStorage.removeItem('currentOffice');
    onLogOut();
  };

  const augmentedProps = {
    userName,
    userFullName,
    mobileView,
    offices,
    selectedOffice,
    openConfirmDialog,
    dialogContent,
    userPoints,
    loading: loading || sendEmailLoading || deleteReservationLoading,
    timeRangeOption,
    onLogoutButtonClick: handleLogoutButtonClick,
    onChangeOffice: handleChangeOffice,
    onDayButtonClick: handleDayButtonClick,
    onCloseConfirmDialog: handleCloseConfirmDialog,
    onChangeTimeRangeOption: handleChangeTimeRangeOption,
    onSendEmailButtonClick: handleSendEmailButtonClick,
  };

  return <WrappedComponent {...augmentedProps} />;
};

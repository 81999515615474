import { gql } from 'apollo-boost';

export const USER_OFFICE = gql`
  query($id: ID!) {
    office(where: { id: $id }) {
      id
      reservationDays {
        date
        freeSeatsCount
        reservedOffice {
          id
          address
        }
      }
    }
  }
`;

import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { USER_POINTS } from '../queries';

export const useUserPoints = () => {
  const { data, error, loading } = useQuery(USER_POINTS);

  const userPoints = _.get(data, 'myPoints', null);

  return { userPoints, error, loading };
};

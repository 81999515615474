import React from 'react';
import moment from 'moment';
import { makeStyles, createStyles } from '@material-ui/core';
import { DayOption, TimeRange } from '../../types';

const useStyles = makeStyles((theme) =>
  createStyles({
    legendContainer: {
      padding: '0 10px',
      color: theme.palette.secondary.dark,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddong: 0,
      },
      [theme.breakpoints.up('lg')]: {
        padding: '0 10px',
      },
    },
    title: {
      margin: '3vw 0 1vw',
      lineHeight: '24px',
      fontWeight: 500,
      color: theme.palette.secondary.dark,
      fontSize: '1.29rem',
      [theme.breakpoints.up('md')]: {
        fontWeight: 700,
        letterSpacing: '0.15px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    smallLegend: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '0.75rem',
      lineHeight: '24px',
      height: 20,
      [theme.breakpoints.up('md')]: {
        width: 'auto',
        margin: '3vw 4vw 0.5vw',
        letterSpacing: '0.15px',
      },
    },
  })
);

interface LegendProps {
  mobileView: boolean;
  timeRangeOption: number;
  dayOptions: DayOption[];
}

export const Legend: React.FC<LegendProps> = ({
  mobileView,
  timeRangeOption,
  dayOptions,
}) => {
  const classes = useStyles();
  const option = !timeRangeOption ? TimeRange.current : TimeRange.upcoming;
  const getFormattedDate = (index: number) =>
    moment(dayOptions[index].date).format('DD/MM/YYYY');

  if (!mobileView) {
    return (
      <div className={classes.legendContainer}>
        <h1 className={classes.title}>{option}</h1>
        <span className={classes.smallLegend}>
          {`${getFormattedDate(0)} - ${getFormattedDate(4)}`}
        </span>
      </div>
    );
  }

  return (
    <div className={classes.legendContainer}>
      <h1 className={classes.title}>{option}</h1>
      <div className={classes.smallLegend}>
        <span>Journée</span>
        <span>Place disponibles</span>
      </div>
    </div>
  );
};

import React from 'react';
import ApolloClient, {
  Operation,
  InMemoryCache,
  IdGetterObj,
  defaultDataIdFromObject,
} from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
import { getToken, getAuthUser } from './utils/getAuthUser';
import { SnackbarProvider, ProviderContext } from 'notistack';
import { Button } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const cache = new InMemoryCache({
  dataIdFromObject: (object: IdGetterObj & { date?: string }) => {
    switch (object.__typename) {
      case 'AvailableReservation':
        return `ProjectDay:${object.date}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

const client = new ApolloClient({
  cache,
  uri: process.env.REACT_APP_API,
  request: async (operation: Operation) => {
    const token = getToken();
    const userEmail = getAuthUser();

    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
        user_email: userEmail ? userEmail.userName : '',
      },
    });
  },
});

const notistackRef: React.RefObject<ProviderContext> = React.createRef();
const onClickClose = (key: number) => () => {
  if (!notistackRef || !notistackRef.current) return;
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          action={(key: number) => (
            <Button onClick={onClickClose(key)}>
              <CloseIcon />
            </Button>
          )}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();

import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  createStyles,
  Link,
  Badge,
  Tooltip,
} from '@material-ui/core';
import {
  EmojiEvents as EmojiEventsIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
} from '@material-ui/icons';
import logo from '../assets/epsa-logo.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    bar: {
      background: theme.palette.secondary.dark,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logo: {
      width: '124px',
      maxWidth: 100,
      display: 'block',
    },
    userName: {
      color: theme.palette.secondary.light,
      fontSize: '1rem',
      padding: 8,
      cursor: 'default',
      fontWeight: 400,
    },
    icon: {
      color: theme.palette.secondary.light,
      transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  })
);

export interface TopBarProps {
  userName: string;
  userFullName: string;
  mobileView: boolean;
  onLogoutButtonClick: () => void;
  userPoints: number;
}

export const TopBar: React.FC<TopBarProps> = ({
  userName,
  userFullName,
  mobileView,
  onLogoutButtonClick,
  userPoints,
}) => {
  const classes = useStyles();

  return (
    <AppBar position='static' className={classes.bar}>
      <Toolbar className={classes.toolbar}>
        <Link href='/'>
          <img src={logo} alt='epsa logo' className={classes.logo} />
        </Link>

        <div className={classes.infoContainer}>
          <Typography variant='h2' className={classes.userName}>
            {mobileView ? userName : userFullName}
          </Typography>
          <Tooltip title='Crédits disponibles'>
            <Badge
              badgeContent={userPoints}
              color='primary'
              max={300}
              showZero={true}
            >
              <EmojiEventsIcon className={classes.icon} />
            </Badge>
          </Tooltip>
          <IconButton onClick={onLogoutButtonClick}>
            <PowerSettingsNewIcon className={classes.icon} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

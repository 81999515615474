import React from 'react';
import _ from 'lodash';
import { makeStyles, createStyles, IconButton } from '@material-ui/core';
import { OfficeDropdown, OfficeDropdownProps } from './OfficeDropdown';
import { Legend } from './Legend';
import { DayOptionList } from './DayOptionList';
import { DayOptionBoxProps } from './DayOptionBox';
import { Loader } from '../Loader';
import { ConfirmDialog, ConfirmDialogProps } from '../ConfirmDialog';
import { DayOption } from '../../types';
import { useUserOffice } from '../../hooks';
import { Drafts as DraftsIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    appContainer: {
      width: '100%',
      height: 'calc(100vh - 112px)',
      padding: '10px',
      backgroundColor: '#F4F4F4',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        height: 'calc(100vh - 64px)',
        backgroundColor: theme.palette.secondary.light,
      },
    },
    errorMessage: {
      height: 'calc(100vh - 112px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        height: 'calc(100vh - 64px)',
      },
    },
    sendEmailButton: {
      color: theme.palette.secondary.dark,
      position: 'absolute',
      right: 10,
      top: 50,
      transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      [theme.breakpoints.up('md')]: {
        top: 10,
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  })
);

interface DayOptionsProps
  extends OfficeDropdownProps,
    ConfirmDialogProps,
    DayOptionBoxProps {
  timeRangeOption: number;
  onSendEmailButtonClick: () => void;
}

export const View: React.FC<DayOptionsProps> = ({
  offices,
  selectedOffice,
  onChangeOffice,
  mobileView,
  timeRangeOption,
  onDayButtonClick,
  openConfirmDialog,
  onCloseConfirmDialog,
  dialogContent,
  userPoints,
  onSendEmailButtonClick,
}) => {
  const classes = useStyles();
  const { office, loading } = useUserOffice({ id: selectedOffice, userPoints });
  const dayOptions: DayOption[] = _.get(office, 'reservationDays', null);

  const getOptionsToRender = (nextWeek?: boolean) => {
    if (!mobileView) {
      return !nextWeek ? dayOptions.slice(0, 5) : dayOptions.slice(5, 10);
    }
    return !timeRangeOption ? dayOptions.slice(0, 5) : dayOptions.slice(5, 10);
  };

  const renderDayOptions = () => {
    if (!dayOptions) {
      if (loading || !selectedOffice || userPoints === null) {
        return <Loader />;
      }

      return (
        <div className={classes.errorMessage}>
          Oups, quelque chose s'est mal passé.
        </div>
      );
    }

    return (
      <>
        <IconButton
          className={classes.sendEmailButton}
          onClick={onSendEmailButtonClick}
        >
          <DraftsIcon />
        </IconButton>
        <Legend
          mobileView={mobileView}
          timeRangeOption={timeRangeOption}
          dayOptions={getOptionsToRender()}
        />
        <DayOptionList
          dayOptions={getOptionsToRender()}
          mobileView={mobileView}
          onDayButtonClick={onDayButtonClick}
          userPoints={userPoints}
        />
        {!mobileView && (
          <>
            <Legend
              mobileView={mobileView}
              timeRangeOption={1}
              dayOptions={getOptionsToRender(true)}
            />
            <DayOptionList
              dayOptions={getOptionsToRender(true)}
              mobileView={mobileView}
              onDayButtonClick={onDayButtonClick}
              userPoints={userPoints}
            />
          </>
        )}
      </>
    );
  };

  return (
    <main>
      <div className={classes.appContainer}>
        <OfficeDropdown
          offices={offices}
          selectedOffice={selectedOffice}
          onChangeOffice={onChangeOffice}
        />
        {renderDayOptions()}
      </div>
      <ConfirmDialog
        openConfirmDialog={openConfirmDialog}
        onCloseConfirmDialog={onCloseConfirmDialog}
        dialogContent={dialogContent}
      />
    </main>
  );
};

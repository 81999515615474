import React, { ReactNode } from 'react';
import classNames from 'classnames';
import {
  makeStyles,
  createStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    alignRight: {
      justifyContent: 'flex-end',
    },
    button: {
      width: 230,
    },
  })
);

export interface ConfirmDialogProps {
  openConfirmDialog: boolean;
  onCloseConfirmDialog: () => void;
  dialogContent: ConfirmDialogContent;
}

export interface ConfirmDialogContent {
  dialogContent?: ReactNode;
  buttonText?: string;
  buttonAction?: () => void;
  dialogTitle?: string;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  openConfirmDialog,
  onCloseConfirmDialog,
  dialogContent: { dialogTitle, dialogContent, buttonAction, buttonText },
}) => {
  const { headerContainer, button, alignRight } = useStyles();
  const headerContainerStyle = !dialogTitle
    ? classNames(headerContainer, alignRight)
    : headerContainer;
  return (
    <Dialog
      open={openConfirmDialog}
      keepMounted
      onClose={onCloseConfirmDialog}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <div className={headerContainerStyle}>
        {dialogTitle && (
          <DialogTitle id='alert-dialog-slide-title'>
            Êtes-vous sûr?
          </DialogTitle>
        )}
        <IconButton
          edge='start'
          color='primary'
          onClick={onCloseConfirmDialog}
          aria-label='close'
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          {dialogContent}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={buttonAction}
          variant='contained'
          color='primary'
          className={button}
        >
          {buttonText || ''}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React, { useState, ChangeEvent } from 'react';
import {
  makeStyles,
  createStyles,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Office } from '../../types';

const useStyles = makeStyles((theme) =>
  createStyles({
    dropdown: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '250px',
      },
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        top: -50,
        left: 150,
        zIndex: 1301,
      },
    },
    container: {
      position: 'relative',
    },
  })
);

export interface OfficeDropdownProps {
  offices: Office[];
  selectedOffice: string;
  onChangeOffice: (office: string) => void;
}

export const OfficeDropdown: React.FC<OfficeDropdownProps> = ({
  offices,
  selectedOffice,
  onChangeOffice,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target;
    onChangeOffice(value as string);
  };

  return (
    <FormControl className={classes.dropdown}>
      <Select
        labelId='office'
        id='office'
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={selectedOffice}
        onChange={onChange}
      >
        {offices.map(({ id, address }) => (
          <MenuItem key={id} value={id}>
            {address}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

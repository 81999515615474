import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#E1B129',
      light: '#FBFBFB',
      dark: '#F3B821',
    },
    secondary: {
      main: '#CBCBCB',
      light: '#FBFBFB',
      dark: '#293647',
    },
  },
  typography: {
    fontFamily: '"Montserrat", arial, sans-serif',
    fontSize: 14,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 475,
      md: 600,
      lg: 800,
      xl: 1440,
    },
  },
  overrides: {
    MuiToolbar: {
      gutters: {
        paddingLeft: 11,
        paddingRight: 11,
      },
    },
    MuiButton: {
      root: {
        color: '#FBFBFB',
        fontSize: '0.75rem',
        lineHeight: '24px',
        width: '100%',
        fontWeight: 600,
        borderRadius: 0,
      },
      containedPrimary: {
        color: '#293647',
      },
      contained: {
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
        '&$disabled': {
          backgroundColor: '#CBCBCB',
          color: '#293647',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
        '@media(min-width: 600px)': {
          padding: 15,
        },
      },
    },
    MuiBadge: {
      root: {
        margin: 8,
        '@media(min-width: 600px)': {
          margin: 15,
        },
      },
      colorPrimary: {
        color: '#FBFBFB',
      },
    },
    MuiInputBase: {
      input: {
        color: '#F3B821',
        fontSize: '1.14rem',
        '@media(min-width: 800px)': {
          color: '#FBFBFB',
          fontSize: '1rem',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: '#F3B821',
          '@media(min-width: 800px)': {
            borderBottomColor: '#FBFBFB',
          },
        },
        '&:after': {
          borderBottom: '1px solid #F3B821!important',
        },
        '&:hover:before': {
          borderBottom: '1px solid #F3B821!important',
        },
      },
    },
    MuiSelect: {
      icon: {
        color: '#F3B821',
        '@media(min-width: 800px)': {
          color: '#FBFBFB',
        },
      },
    },
    MuiMenuItem: {
      root: {
        '@media(min-width: 600px)': {
          fontSize: '0.8rem',
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        width: '100%',
        '@media(min-width: 600px)': {
          height: 'auto',
        },
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        paddingBottom: 15,
      },
    },
    MuiTypography: {
      h6: {
        fontSize: '1.36rem',
        color: '#293647',
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: '#293647',
      },
    },
    MuiPaper: {
      elevation8: {
        top: '100px !important',
        left: '10px !important',
        '@media(min-width: 475px)': {
          top: '110px !important',
        },
        '@media(min-width: 800px)': {
          top: '48px !important',
          left: '150px !important',
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        flexWrap: 'nowrap',
      },
      action: {
        paddingLeft: 0,
      },
    },
  },
});

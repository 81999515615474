import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { DayOptionBox, DayOptionBoxProps } from './DayOptionBox';
import { DayOption } from '../../types';

const useStyles = makeStyles((theme) =>
  createStyles({
    listContainer: {
      width: '100%',
      height: 'calc(100% - 92px - 4vw)',
      minHeight: 340,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '5px 0 10px',
      marginBottom: 56,
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        height: 'auto',
        minHeight: '170px',
        padding: 0,
        margin: '0 0 20px 0',
        backgroundColor: theme.palette.secondary.light,
      },
      [theme.breakpoints.up('lg')]: {
        padding: '0 10px',
      },
    },
  })
);

interface DayOptionListProps extends DayOptionBoxProps {
  dayOptions: DayOption[];
}

export const DayOptionList: React.FC<DayOptionListProps> = ({
  dayOptions,
  onDayButtonClick,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.listContainer}>
      {dayOptions.map((option) => (
        <DayOptionBox
          key={option.date}
          onDayButtonClick={onDayButtonClick}
          {...option}
          {...rest}
        />
      ))}
    </div>
  );
};

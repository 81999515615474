export interface Office {
  id: string;
  address: string;
}

export interface DayOption {
  date: string;
  freeSeatsCount: number;
  reservedOffice: {
    id: string;
    address: string;
  };
}

export enum TimeRange {
  current = 'Cette semaine',
  upcoming = 'Semaine prochaine',
}

import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { USER_OFFICES } from '../queries';

export const useUserOffices = () => {
  const { data } = useQuery(USER_OFFICES);

  const offices = _.get(data, 'offices', []);

  return { offices };
};

import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import { VariantType } from 'notistack';
import { CREATE_RESERVATION } from '../mutations';
import { USER_OFFICE, USER_POINTS } from '../queries';

export const useCreateReservation = (officeId: string) => {
  const [createReservation, { loading }] = useMutation(CREATE_RESERVATION);
  const [userOfficeQuery] = useLazyQuery(USER_OFFICE);

  const handleCreateReservation = async ({
    date,
    userEmail,
    onCloseConfirmDialog,
    onOpenSnackbar,
  }: {
    date: string;
    userEmail: string;
    onCloseConfirmDialog: () => void;
    onOpenSnackbar: (variant: VariantType, message: string) => void;
  }) => {
    try {
      await createReservation({
        variables: {
          data: {
            date,
            userEmail,
            office: {
              id: officeId,
            },
          },
        },
        refetchQueries: [
          {
            query: USER_OFFICE,
            variables: {
              id: officeId,
            },
          },
          {
            query: USER_POINTS,
          },
        ],
      });
      onOpenSnackbar('success', 'Votre place a bien été réservée.');
    } catch (error) {
      const errorMessage = _.get(
        error,
        'graphQLErrors[0].message',
        "Oups, quelque chose s'est mal passé."
      );
      onOpenSnackbar('error', errorMessage);
      userOfficeQuery({
        variables: {
          id: officeId,
        },
      });
    }

    onCloseConfirmDialog();
  };

  return { handleCreateReservation, loading };
};

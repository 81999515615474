import React from 'react';
import { makeStyles, createStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    pageContainer: {
      zIndex: 1301,
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    loaderContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1301,
    },
    loader: {
      color: theme.palette.secondary.dark,
    },
  })
);

export const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.pageContainer}>
      <div className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} />
      </div>
    </div>
  );
};

import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import classNames from 'classnames';
import { makeStyles, createStyles, Button, Avatar } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { DayOption } from '../../types';

const useStyles = makeStyles((theme) =>
  createStyles({
    optionContainer: {
      width: '100%',
      color: theme.palette.secondary.dark,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 13px',
      lineHeight: '24px',
      fontSize: '1.39rem',
      fontWeight: 300,
      textAlign: 'left',
      borderRadius: 2,
      [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
        width: 'calc(20% - 2vw)',
        minWidth: 110,
        maxHeight: 180,
        padding: 0,
        fontSize: '1.19rem',
      },
    },
    dayName: {
      textTransform: 'capitalize',
      fontSize: '1.46rem',
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        fontSize: '1.26rem',
      },
    },
    avatar: {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: '1.36rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
    },
    disabledAvatar: {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.main,
    },
    containerButton: {
      padding: 0,
      height: 'calc(20% - 3vw)',
      width: '100%',
      backgroundColor: theme.palette.secondary.light,
      minHeight: 55,
      borderRadius: 2,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    disabledContainerButton: {
      opacity: 0.65,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'inherit',
        color: 'inherit',
        opacity: 0.4,
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    disabledBasicButton: {
      backgroundColor: `${theme.palette.secondary.dark} !important`,
      color: `${theme.palette.secondary.light} !important`,
    },
    dateContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '65%',
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-end',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        width: 'calc(100% - 5px)',
        padding: '0 5px 5px 0',
      },
    },
    slotsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      fontSize: '0.75rem',
      lineHeight: '20px',
    },
    reservedContainerButton: {
      opacity: 0.6,
    },
  })
);

export interface DayOptionBoxProps {
  mobileView: boolean;
  onDayButtonClick: (
    date: string,
    reservedOffice?: { id: string; address: string }
  ) => void;
  userPoints: number;
}

export const DayOptionBox: React.FC<DayOptionBoxProps & DayOption> = ({
  date,
  reservedOffice,
  freeSeatsCount,
  mobileView,
  onDayButtonClick,
  userPoints,
}) => {
  const {
    containerButton,
    disabledContainerButton,
    optionContainer,
    dayName,
    avatar,
    disabledAvatar,
    dateContainer,
    slotsContainer,
    disabledBasicButton,
    reservedContainerButton,
  } = useStyles();
  moment.locale('fr');

  const isBeforeToday = moment(date).isBefore(
    moment().format('YYYY-MM-DD'),
    'day'
  );
  const disabled =
    ((userPoints < 1 || freeSeatsCount < 1) && !reservedOffice) || isBeforeToday;
  const containerButtonStyle = disabled
    ? classNames(containerButton, disabledContainerButton)
    : containerButton;
  const optionContainerStyle = disabled
    ? classNames(optionContainer, disabledContainerButton)
    : reservedOffice
    ? classNames(optionContainer, reservedContainerButton)
    : optionContainer;
  const avatarStyle = disabled ? classNames(avatar, disabledAvatar) : avatar;

  const getAvatarAmount = () => {
    if (reservedOffice) {
      return <CheckIcon />;
    }
    return isBeforeToday ? 0 : freeSeatsCount;
  };

  const handleButtonClick = () => onDayButtonClick(date, reservedOffice);

  if (!mobileView) {
    return (
      <div className={optionContainerStyle}>
        <div className={dateContainer}>
          <h2 className={dayName}>{moment(date).format('dddd')}</h2>
          {moment(date).format('Do')}
        </div>
        <div className={slotsContainer}>
          <span>
            {reservedOffice ? (
              <>
                Réservé à <b>{reservedOffice.address.split(',')[0]}</b>
              </>
            ) : (
              'Place disponibles'
            )}
          </span>
          <Avatar className={avatarStyle}>{getAvatarAmount()}</Avatar>
        </div>

        <Button
          variant='contained'
          color='primary'
          disabled={disabled}
          onClick={handleButtonClick}
          className={disabled ? disabledBasicButton : ''}
        >
          {reservedOffice ? 'Place réservée' : 'Réservez votre place'}
        </Button>
      </div>
    );
  }

  return (
    <Button
      className={containerButtonStyle}
      disabled={disabled}
      variant='contained'
      onClick={handleButtonClick}
    >
      <div className={optionContainerStyle}>
        <div className={dateContainer}>
          <h2 className={dayName}>{moment(date).format('dddd')}</h2>
          {moment(date).format('Do/MM')}
        </div>

        <Avatar className={avatarStyle}>{getAvatarAmount()}</Avatar>
      </div>
    </Button>
  );
};

import React from 'react';
import { TopBar } from '../components/TopBar';
import { BottomNav } from '../components/BottomNavigation';
import { DayOptions } from '../components/DayOptions';
import { Loader } from '../components/Loader';
import {
  withAvailableReservations,
  AvailableReservationsProps,
} from '../hocs/withAvailableReservations';

const AvailableReservations: React.FC<AvailableReservationsProps> = ({
  userName,
  userFullName,
  mobileView,
  onLogoutButtonClick,
  userPoints,
  loading,
  timeRangeOption,
  onChangeTimeRangeOption,
  ...rest
}) => (
  <div className='App'>
    <TopBar
      userName={userName}
      userFullName={userFullName}
      mobileView={mobileView}
      onLogoutButtonClick={onLogoutButtonClick}
      userPoints={userPoints}
    />

    <DayOptions
      mobileView={mobileView}
      timeRangeOption={timeRangeOption}
      userPoints={userPoints}
      {...rest}
    />
    {loading && <Loader />}

    <BottomNav
      timeRangeOption={timeRangeOption}
      onChangeTimeRangeOption={onChangeTimeRangeOption}
    />
  </div>
);

export default withAvailableReservations(AvailableReservations);

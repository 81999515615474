import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { VariantType } from 'notistack';
import { SEND_EMAIL_WITH_RESERVATIONS } from '../mutations';

export const useSendEmailWithReservations = () => {
  const [sendEmailWithReservations, { loading }] = useMutation(
    SEND_EMAIL_WITH_RESERVATIONS
  );

  const handleSendEmailWithReservations = async ({
    onOpenSnackbar,
    onCloseConfirmDialog,
  }: {
    onCloseConfirmDialog: () => void;
    onOpenSnackbar: (variant: VariantType, message: string) => void;
  }) => {
    try {
      const res = await sendEmailWithReservations();
      const sendedEmail = _.get(res, 'data.sendEmailWithReservations');
      if (sendedEmail) {
        onOpenSnackbar('success', 'Votre message a bien été envoyé');
      } else {
        onOpenSnackbar(
          'warning',
          "Vous n'avez pas actuellement de reservations pour les jours à venir"
        );
      }
    } catch (error) {
      onOpenSnackbar('error', "Oups, quelque chose s'est mal passé.");
    }

    onCloseConfirmDialog();
  };

  return { handleSendEmailWithReservations, sendEmailLoading: loading };
};

import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { USER_OFFICE } from '../queries';

export const useUserOffice = ({
  id,
  userPoints,
}: {
  id: string;
  userPoints: number | null;
}) => {
  const { data, loading } = useQuery(USER_OFFICE, {
    variables: { id },
    skip: !id || userPoints === null,
    pollInterval: 30000,
    fetchPolicy: 'cache-and-network',
  });

  const office = _.get(data, 'office', null);

  return { office, loading };
};

import { gql } from 'apollo-boost';

export const USER_OFFICES = gql`
  query {
    offices {
      id
      address
    }
  }
`;

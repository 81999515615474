import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { VariantType } from 'notistack';
import { DELETE_RESERVATION } from '../mutations';
import { USER_OFFICE, USER_POINTS } from '../queries';

export const useDeleteReservation = (officeId: string) => {
  const [deleteReservation, { loading }] = useMutation(DELETE_RESERVATION);

  const handleDeleteReservation = async ({
    date,
    officeIdWithReservation,
    onCloseConfirmDialog,
    onOpenSnackbar,
  }: {
    date: string;
    officeIdWithReservation: string;
    onCloseConfirmDialog: () => void;
    onOpenSnackbar: (variant: VariantType, message: string) => void;
  }) => {
    try {
      await deleteReservation({
        variables: {
          data: {
            date,
            office: {
              id: officeIdWithReservation,
            },
          },
        },
        refetchQueries: [
          {
            query: USER_OFFICE,
            variables: {
              id: officeId,
            },
          },
          {
            query: USER_POINTS,
          },
        ],
      });
      onOpenSnackbar('success', 'Votre réservation a bien été annulée.');
    } catch (error) {
      const errorMessage = _.get(
        error,
        'graphQLErrors[0].message',
        "Oups, quelque chose s'est mal passé."
      );
      onOpenSnackbar('error', errorMessage);
    }

    onCloseConfirmDialog();
  };

  return { handleDeleteReservation, deleteReservationLoading: loading };
};
